import React from 'react';
import SEO from '@components/seo';

import { usePlan } from '@util/providers/Plan';
import { useUser } from '@util/providers/AuthProvider';

import PageHeader from '@ui/PageHeader';
import DetailsModule from '../modules/Details';
import { useTranslation } from 'react-i18next';
import ViewAllPlansButton from '../components/Miscellaneous/ViewAllPlansButton';
import BackButton from '../components/Miscellaneous/BackButton';
import { staticPlanDetailsLicenses } from '@data';
import { useLocale } from '@util/providers/Locale';
import { useLicense } from '@util/providers/License';

const Organization = () => {
  const { t } = useTranslation();
  const { bmoUser } = useUser();
  const { plans } = usePlan();
  const { locale, selectedCurrency } = useLocale();
  const staticLicensePlans = staticPlanDetailsLicenses();
  const { licensePlans } = useLicense();
  var location =
    selectedCurrency === 'USD' || selectedCurrency === 'CAD'
      ? selectedCurrency
      : locale?.localeDetails?.isoCode;
  var isSubscriptionPlan = true;

  const organizationDetails = {
    summary: t('planDetails:organization.summary'),
    topReasons: {
      header: t('planDetails:organization.topReasons.header'),
      points: [
        t('planDetails:organization.topReasons.points.0'),
        t('planDetails:organization.topReasons.points.1'),
        t('planDetails:organization.topReasons.points.2'),
        t('planDetails:organization.topReasons.points.3'),
        t('planDetails:organization.topReasons.points.4'),
      ],
    },
    summaryEnd: t('planDetails:organization.summaryEnd'),
    features: [
      {
        featureTitle: t('planDetails:organization.features.0.featureTitle'),
        featureDescription: t(
          'planDetails:organization.features.0.featureDescription'
        ),
      },
      {
        featureTitle: t('planDetails:organization.features.1.featureTitle'),
        featureDescription: t(
          'planDetails:organization.features.1.featureDescription'
        ),
      },
      {
        featureTitle: t('planDetails:organization.features.2.featureTitle'),
        featureDescription: t(
          'planDetails:organization.features.2.featureDescription'
        ),
      },
      {
        featureTitle: t('planDetails:organization.features.3.featureTitle'),
        featureDescription: t(
          'planDetails:organization.features.3.featureDescription'
        ),
      },
      {
        featureTitle: t('planDetails:organization.features.4.featureTitle'),
        featureDescription: t(
          'planDetails:organization.features.4.featureDescription'
        ),
      },
    ],
    advantages: [
      {
        advantageTitle: t(
          'planDetails:organization.advantages.0.advantageTitle'
        ),
        advantagePoints: [
          t('planDetails:organization.advantages.0.advantagePoints.0'),
          t('planDetails:organization.advantages.0.advantagePoints.1'),
          t('planDetails:organization.advantages.0.advantagePoints.2'),
          t('planDetails:organization.advantages.0.advantagePoints.3'),
          t('planDetails:organization.advantages.0.advantagePoints.4'),
        ],
      },
      {
        advantageTitle: t(
          'planDetails:organization.advantages.1.advantageTitle'
        ),
        advantagePoints: [
          t('planDetails:organization.advantages.1.advantagePoints.0'),
          t('planDetails:organization.advantages.1.advantagePoints.1'),
          t('planDetails:organization.advantages.1.advantagePoints.2'),
          t('planDetails:organization.advantages.1.advantagePoints.3'),
        ],
      },
      {
        advantageTitle: t(
          'planDetails:organization.advantages.2.advantageTitle'
        ),
        advantagePoints: [
          t('planDetails:organization.advantages.2.advantagePoints.0'),
          t('planDetails:organization.advantages.2.advantagePoints.1'),
          t('planDetails:organization.advantages.2.advantagePoints.2'),
        ],
      },
      {
        advantageTitle: t(
          'planDetails:organization.advantages.3.advantageTitle'
        ),
        advantagePoints: [
          t('planDetails:organization.advantages.3.advantagePoints.0'),
          t('planDetails:organization.advantages.3.advantagePoints.1'),
          t('planDetails:organization.advantages.3.advantagePoints.2'),
          t('planDetails:organization.advantages.3.advantagePoints.3'),
        ],
      },
    ],
    requirements: {
      operatingSystem: [
        [
          t('planDetails:organization.requirements.operatingSystem.0.0'),
          t('planDetails:organization.requirements.operatingSystem.0.1'),
          t('planDetails:organization.requirements.operatingSystem.0.2'),
        ],
        [
          t('planDetails:organization.requirements.operatingSystem.1.0'),
          t('planDetails:organization.requirements.operatingSystem.1.1'),
          t('planDetails:organization.requirements.operatingSystem.1.2'),
        ],
        [
          t('planDetails:community.requirements.operatingSystem.2.0'),
          t('planDetails:community.requirements.operatingSystem.2.1'),
          t('planDetails:community.requirements.operatingSystem.2.2'),
        ],
      ],
      browser: [
        [
          t('planDetails:organization.requirements.browser.0.0'),
          t('planDetails:organization.requirements.browser.0.1'),
          t('planDetails:organization.requirements.browser.0.2'),
        ],
        [
          t('planDetails:organization.requirements.browser.1.0'),
          t('planDetails:organization.requirements.browser.1.1'),
          t('planDetails:organization.requirements.browser.1.2'),
        ],
        [
          t('planDetails:organization.requirements.browser.2.0'),
          t('planDetails:organization.requirements.browser.2.1'),
          t('planDetails:organization.requirements.browser.2.2'),
        ],
        [
          t('planDetails:organization.requirements.browser.3.0'),
          t('planDetails:organization.requirements.browser.3.1'),
          t('planDetails:organization.requirements.browser.3.2'),
        ],
        [
          t('planDetails:organization.requirements.browser.4.0'),
          t('planDetails:organization.requirements.browser.4.1'),
          t('planDetails:organization.requirements.browser.4.2'),
        ],
      ],
    },
  };

  const isSubscriber =
    bmoUser &&
    bmoUser.zuoraInfo &&
    bmoUser.zuoraInfo.subscriptions &&
    bmoUser.zuoraInfo.subscriptions.length !== 0;

  if (!plans || plans.length === 0) return null;

  var currentPlan = plans.find(p => p.key === 'organization');

  if (!currentPlan) {
    isSubscriptionPlan = false;
    currentPlan = staticLicensePlans.find(p => p.key === 'organization');
    if (!currentPlan) {
      return null;
    }
    if (currentPlan.key === 'boardmakerLicense') {
      currentPlan.staticRatePlan.price =
        licensePlans?.pricing[location]?.display ||
        licensePlans?.pricing[location]?.price;
    }
  }

  return (
    <>
      <SEO title={currentPlan.name} />
      <div className="row mb-4">
        <PageHeader className="col-12 col-md-11 col-lg-7">
          {isSubscriptionPlan
            ? `Boardmaker ${currentPlan.name}` + ' ' + t('software')
            : t('boardmaker7')}
        </PageHeader>
        {!isSubscriber ? (
          <div className="col-12 mt-3 d-md-none">{<ViewAllPlansButton />}</div>
        ) : (
          <div className="col-12 mt-3">{<BackButton />}</div>
        )}
      </div>
      <DetailsModule planDetails={organizationDetails} plan={currentPlan} />
    </>
  );
};

export default Organization;
